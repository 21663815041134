<template>
    <div class="mijnps-pb">
        <FullscreenLoader v-if="!user" />
        <template v-else>
            <button class="btn" @click="$router.go(-1)">Ga terug</button>
            <h2 class="oleo">{{ voorstel.Voornaam }}</h2>
            <template>
                <div
                    class="mijnps-pb__foto"
                    v-if="voorstel.Foto_code == '1'"
                    :class="{ 'mijnps-pb__foto--groot': fotoGroot }"
                >
                    <img
                        :src="voorstel.foto_p2"
                        alt="Profielfoto niet gevonden"
                        load="lazy"
                        @click="handleOpenFotos"
                        style="width: 100%; height: 100%; object-fit: cover; margin: 0"
                    />
                    <span
                        class="fototeller"
                        v-if="voorstel.aantal_extra_fotos_voorstel && voorstel.aantal_extra_fotos_voorstel > 0"
                    >
                        Klik hier voor meer foto's
                    </span>
                </div>
                <div
                    class="mijnps-pb__foto nog-geen-foto"
                    style="display: flex; justify-content: center; align-items: center"
                    v-else
                >
                    <h4 class="oleo" style="display: block">
                        Foto {{ voorstel.Foto_code == '0' ? 'nog ' : '' }}niet zichtbaar
                    </h4>
                </div>
            </template>
            <div class="mijnps-pb__tekst">
                {{ voorstel.persoonsbeschrijving_p2.PB }}
            </div>

            <button
                class="btn"
                v-if="voorstel.Foto_code == '0' && voorstel.Openstaand === '1'"
                style="margin-bottom: 1rem"
                @click="handleToonFoto"
            >
                Spreekt de beschrijving je aan en wil je de foto zien? Klik dan hier om de foto direct zichtbaar te
                maken.
            </button>

            <template v-if="voorstel.Openstaand === '1'">
                <ErrorMessage />
                <template v-if="this.voorstel.reacties_op_voorstel.length > 0">
                    <div class="al-gereageerd">
                        <p>
                            Je hebt op
                            {{
                                toonKorteDatum(
                                    voorstel.reacties_op_voorstel[voorstel.reacties_op_voorstel.length - 1].DT_ingevoerd
                                )
                            }}
                            op dit voorstel gereageerd. <br />
                            Binnen 2 werkdagen verwerken we je reactie en sturen daarvan nog een bevestiging. Het is
                            niet nodig opnieuw hieronder je reactie door te geven.
                        </p>
                    </div>
                </template>
                <h3 class="oleo">Reageer op voorstel</h3>

                <form action="#" class="mijnps-form" style="display: block">
                    <label for="reactiePos" v-if="voorstel.Foto_code !== '0'">
                        <input
                            type="radio"
                            name="reactieBtn"
                            id="reactiePos"
                            value="JaMetFoto"
                            v-model="reactieOpVoorstel.reactie"
                        />
                        <span>Ik reageer positief.</span>
                    </label>
                    <label for="reactiePosZonderFoto" v-if="voorstel.Foto_code === '0'" style="display: block">
                        <input
                            type="radio"
                            name="reactieBtn"
                            id="reactiePosZonderFoto"
                            value="JaZonderFoto"
                            v-model="reactieOpVoorstel.reactie"
                        />
                        <span>Ik reageer positief. De foto hoef ik niet te zien</span>
                    </label>
                    <label for="reactieNeg" style="display: block">
                        <input
                            type="radio"
                            name="reactieBtn"
                            id="reactieNeg"
                            value="NeeZonderFoto"
                            v-model="reactieOpVoorstel.reactie"
                        />
                        <span
                            >Ik reageer afwijzend
                            {{ voorstel.Foto_code === '0' ? '(De foto wordt niet zichtbaar)' : '' }}</span
                        >
                    </label>

                    <div v-if="wilAfwijzendReageren">
                        <p style="font-weight: bold; margin-top: 1rem">
                            Heb je onze tips bij het reageren op voorstellen al gelezen?
                            <router-link to="/reactietips">Klik hier!</router-link>
                        </p>
                    </div>

                    <div style="margin-top: 1.5rem" v-show="reactieOpVoorstel.reactie">
                        <label for="reactieMotivatie"
                            >Motivatie (Een bericht aan je matchmaker. {{ voorstel.Voornaam }} krijgt dit niet te
                            lezen.)</label
                        >
                        <br />
                        <textarea
                            name="reactieMotivatie"
                            id="reactieMotivatie"
                            style="width: 80%; height: 30vh"
                            v-model="reactieOpVoorstel.motivatie"
                        ></textarea>
                    </div>

                    <input
                        type="submit"
                        value="Verstuur reactie"
                        class="btn"
                        :disabled="reactieVerzonden"
                        @click.prevent="handleReactie"
                    />
                </form>
            </template>
            <div style="text-align: center" v-else>
                <hr />
                <em>
                    Het is niet meer mogelijk om op dit voorstel te reageren. Heb je toch vragen over dit voorstel?
                    Stuur dan
                    <a href="/nieuw-bericht" @click.prevent="$router.push('/nieuw-bericht')">een bericht</a>
                    naar jouw contactpersoon of maak <BelLink>een belafspraak</BelLink>.
                </em>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ErrorMessage from '../components/ErrorMessage';
    import { errorToMessage } from '../functions/errorHandler';
    import { toonKorteDatum } from '../functions/toonDatum';
    import { scrollToTop } from '../functions/scrollFunctions';

    export default {
        data() {
            return {
                fotoGroot: false,
                reactieOpVoorstel: {
                    reactie: null,
                    motivatie: '',
                    voorstelNummer: this.$route.params.id,
                },
                reactieVerzonden: false,
            };
        },
        computed: {
            ...mapGetters(['user']),
            voorstel() {
                return this.$store.getters.haalVoorstelNummer(this.$route.params.id);
            },
            wilAfwijzendReageren() {
                return this.reactieOpVoorstel.reactie === 'NeeZonderFoto';
            },
        },
        components: {
            ErrorMessage,
        },
        methods: {
            ...mapActions(['stuurReactieOpVoorstel', 'addNotification', 'veranderFotoStatus', 'setFotos']),
            scrollToTop,
            toonKorteDatum,
            handleOpenFotos() {
                this.setFotos({
                    foto: this.voorstel.foto_p2,
                    extra_fotos: this.voorstel.extra_fotos || null,
                });
            },
            handleToonFoto() {
                this.veranderFotoStatus({
                    voorstelnummer: this.voorstel.Voorstelnummer,
                    gekozenCode: '1',
                });
                this.scrollToTop();
            },
            maakReactieObject() {
                const obj = {
                    reactie: null,
                    motivatie: this.reactieOpVoorstel.motivatie,
                    voorstelNummer: this.$route.params.id,
                    gekozenCode: null,
                };

                if (this.reactieOpVoorstel.reactie === 'JaMetFoto') {
                    obj.reactie = '2';
                    obj.gekozenCode = '0';
                }
                if (this.reactieOpVoorstel.reactie === 'JaZonderFoto') {
                    obj.reactie = '2';
                    obj.gekozenCode = '0';
                }
                if (this.reactieOpVoorstel.reactie === 'NeeZonderFoto') {
                    obj.reactie = '3';
                    obj.gekozenCode = '2';
                }

                return obj;
            },
            handleReactie() {
                this.reactieVerzonden = true;

                // Geen reactie gekozen
                if (!this.reactieOpVoorstel.reactie) {
                    this.$store.commit('SET_ERROR', 'Nog geen reactie gekozen. Kies positief of afwijzend.');
                    this.reactieVerzonden = false;
                    return;
                }

                // Maak reactie aan
                const reactieObject = this.maakReactieObject();

                // Geen lege afwijzende reacties
                if (reactieObject.reactie === '3' && reactieObject.motivatie.length < 4) {
                    this.$store.commit(
                        'SET_ERROR',
                        'Een motivatie bij een afwijzende reactie is belangrijk. Graag vernemen wij de reden van afwijzing.'
                    );
                    this.reactieVerzonden = false;
                    return;
                }

                // Verstuur reactie
                this.stuurReactieOpVoorstel(reactieObject)
                    .then(() => {
                        // If success
                        this.addNotification('Reactie verzonden');
                        if (this.voorstel.Foto_code === '0' && reactieObject.gekozenCode !== '0') {
                            this.veranderFotoStatus({
                                voorstelnummer: this.reactieOpVoorstel.voorstelNummer,
                                gekozenCode: reactieObject.gekozenCode,
                            });
                        }
                        this.reactieVerzonden = true;
                        this.$router.push('/bedankt?type=reactie');
                    })
                    .catch((err) => {
                        // If error
                        const msg = errorToMessage(err);
                        console.error(msg);
                        this.$store.commit('SET_ERROR', msg);
                        this.reactieVerzonden = false;
                    });
            },
        },
        mounted() {
            scrollTo(0, 0);
        },
    };
</script>

<style lang="scss">
    .al-gereageerd {
        padding: 1rem;
        background: rgba($color: #ff5500, $alpha: 0.7);
        margin-top: 0.8rem;
        display: block;
    }

    .nog-geen-foto {
        &::before {
            content: 'Lees eerst de beschrijving. Indien gewenst kan dan de foto getoond worden.';
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f50;
            color: black;
            text-align: center;
            padding: 0.5rem;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 0.2s ease;
            overflow: hidden;
        }

        &:hover::before {
            opacity: 0.95;
        }
    }
</style>
